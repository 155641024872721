/* eslint-disable no-case-declarations */
import { combineReducers } from "redux"

import { CLEAR_LOADING, SET_LOADING_MESSAGE, SHOW_LOADING, LOGOUT, LOGIN, SET_INFO_LIVRAISON, UPDATE_INFOS_PERSO, SET_PRODUCT_SORT } from "../actions/"

import {
  REMOVE_PRODUCT,
  SET_PICKUP_POINT,
  REMOVE_ALL_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  RESET_PICKUP_ZONE,
  SET_ZONE,
} from "../actions/panier"



const panierR = (
  state = {
    products: [],
    pickupPoint: null,
    zone:null,
  },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT:
      const newProduct = {
        ...action.payload,
        position: state.products.length + 1,
      }
      const newProducts = [...state.products]
      newProducts.unshift(newProduct)

      return {
        ...state,
        products: newProducts,

      }

    case UPDATE_PRODUCT: {
      const productrmvd = state.products.find(product => product._id === action.payload._id)
      const newProductUpd = {
        ...productrmvd,
        qty: action.payload.qty + productrmvd.qty,
        ...action.payload,
      }
      const newProductsUpd = state.products.filter(product => product._id !== action.payload._id)
      newProductsUpd.unshift(newProductUpd)
      return {
        ...state,
        products: newProductsUpd,
      }
    }

    case REMOVE_PRODUCT:
      const newProductsRm = [...state.products].filter(p => p._id !== action._id)
      return {
        ...state,
        products: newProductsRm,
      }

    case REMOVE_ALL_PRODUCTS:
    case LOGOUT:
      return {
        ...state,
        products: [],
      }
    case SET_PICKUP_POINT:
      return { ...state, pickupPoint: action.id_point }
    case SET_ZONE:
      return { ...state, zone: action.id_zone }
    case RESET_PICKUP_ZONE:
      return { ...state, zone: null, pickupPoint: null }
    case LOGIN:
      return {
        ...state,
        products: action.payload.currentOrders,
      }
    default:
      return state
  }
}

const LoginR = (state = { identifiants: null, lastUpdate: 0 }, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        identifiants: action.payload,
        lastUpdate: Math.round(new Date() / 1000),
      }
    case UPDATE_INFOS_PERSO:
      return {
        ...state,
        identifiants: {...state.identifiants, ...action.payload},
        lastUpdate: Math.round(new Date() / 1000),
      }
    case LOGOUT:
      return { ...state, identifiants: null }
    case SET_PRODUCT_SORT:
      return {
        ...state,
        identifiants: {...state.identifiants, customer_product_sort_by_producer: action.sort}
      }
    default:
      return state
  }
}

/**
 * status : null pas de réponse
 * true : succès
 * false: échec
 */
const loadingR = (state = { loading: false, status: null, message: "", thisZone: null, categories: [], livraisons: [], produits: [], dateMaj: null }, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return { ...state, loading: true, status: null, message: "" }
    case SET_LOADING_MESSAGE:
      return { ...state, message: action.message }
    case CLEAR_LOADING:
      return { ...state, loading: false, status: action.status, message: action.message }
    case SET_INFO_LIVRAISON:
      return { ...state, categories: action.categories, livraisons: action.livraisons, produits: action.produits, thisZone: action.thisZone, dateMaj: new Date().getTime() }
    default:
      return state
  }
}

const allReducers = combineReducers({
  panierR,
  identifiants: LoginR,
  loadingR,
})

export default allReducers
