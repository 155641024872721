export const ADD_PRODUCT = "ADD_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export const REMOVE_ALL_PRODUCTS = "REMOVE_ALL_PRODUCTS"
export const SET_PICKUP_POINT = "SET_PICKUP_POINT"
export const SET_ZONE = "SET_ZONE"
export const RESET_AFTER_ADD = "RESET_AFTER_ADD"
export const RESET_PICKUP_ZONE = "RESET_PICKUP_ZONE"


export const addProduct = product => {
  return {
    type: ADD_PRODUCT,
    payload: product,
  }
}

export const updateProduct = product => {
  return {
    type: UPDATE_PRODUCT,
    payload: product,
  }
}

export const removeProduct = _id => {
  return {
    type: REMOVE_PRODUCT,
    _id,
  }
}


export const removeAllProducts = data => {
  return {
    type: REMOVE_ALL_PRODUCTS,
    payload: data,
  }
}

export const resetAfterAdd = () => {
  return {
    type: RESET_AFTER_ADD,
  }
}

export const setPickupPoint = id_point => {
  return {
    type: SET_PICKUP_POINT,
    id_point,
  }
}

export const resetPickup_Zone = () => {
  return {
    type: RESET_PICKUP_ZONE,
  }
}

export const setZone = id_zone => {
  return {
    type: SET_ZONE,
    id_zone,
  }
}
