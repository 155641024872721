// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-livraison-js": () => import("./../../../src/containers/livraison.js" /* webpackChunkName: "component---src-containers-livraison-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-association-js": () => import("./../../../src/pages/association.js" /* webpackChunkName: "component---src-pages-association-js" */),
  "component---src-pages-connexion-js": () => import("./../../../src/pages/connexion.js" /* webpackChunkName: "component---src-pages-connexion-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donnees-personnelles-js": () => import("./../../../src/pages/donnees-personnelles.js" /* webpackChunkName: "component---src-pages-donnees-personnelles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-init-mdp-js": () => import("./../../../src/pages/init-mdp.js" /* webpackChunkName: "component---src-pages-init-mdp-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-panier-js": () => import("./../../../src/pages/panier.js" /* webpackChunkName: "component---src-pages-panier-js" */),
  "component---src-pages-producteur-afsame-js": () => import("./../../../src/pages/producteur/afsame.js" /* webpackChunkName: "component---src-pages-producteur-afsame-js" */),
  "component---src-pages-producteur-cabas-la-planee-js": () => import("./../../../src/pages/producteur/cabas-la-planee.js" /* webpackChunkName: "component---src-pages-producteur-cabas-la-planee-js" */),
  "component---src-pages-producteur-fermedethey-js": () => import("./../../../src/pages/producteur/fermedethey.js" /* webpackChunkName: "component---src-pages-producteur-fermedethey-js" */),
  "component---src-pages-producteur-index-js": () => import("./../../../src/pages/producteur/index.js" /* webpackChunkName: "component---src-pages-producteur-index-js" */),
  "component---src-pages-producteur-rondeau-js": () => import("./../../../src/pages/producteur/rondeau.js" /* webpackChunkName: "component---src-pages-producteur-rondeau-js" */),
  "component---src-pages-statuts-js": () => import("./../../../src/pages/statuts.js" /* webpackChunkName: "component---src-pages-statuts-js" */)
}

